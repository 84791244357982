
import { computed } from '@vue/reactivity';
import { defineComponent, PropType, toRefs } from 'vue';
import CardComponent from '../CardComponent.vue';
import ScoreCircular from '../charts/ScoreCircular.vue';
import scoreHelper from '@/helpers/Score.helper';
import { Score } from '@/models/Score';

export default defineComponent({
  components: { CardComponent, ScoreCircular },
  props: {
    scores: {
      required: true,
      type: Object as PropType<Score[]>,
    },
  },
  setup(props) {
    const { scores } = toRefs(props);
    const efficiency = computed(() => scores.value?.find((score) => score.type === 'EFFICIENCY'));
    const allOtherScores = computed(() => scores.value?.filter((score) => score.type !== 'EFFICIENCY'));

    return { efficiency, allOtherScores, scoreHelper };
  },
});
