
import Statistics, { ChartType } from '@/models/Statistics';
import { computed } from '@vue/reactivity';
import { defineComponent, PropType } from 'vue';
import CgmAnalysisSummaryScores from './CgmAnalysisSummaryScores.vue';
import CgmAnalysisSummaryRecommendations from './CgmAnalysisSummaryRecommendations.vue';
import CgmAnalysisSummaryHeatMap from './CgmAnalysisSummaryHeatMap.vue';
import LoadingComponent from '@/components/LoadingComponent.vue';

export default defineComponent({
  components: {
    CgmAnalysisSummaryScores,
    CgmAnalysisSummaryRecommendations,
    CgmAnalysisSummaryHeatMap,
    LoadingComponent,
  },
  props: {
    statistics: {
      required: true,
      type: Object as PropType<Statistics>,
    },
  },
  setup(props) {
    const heatMapData = computed(
      () => props.statistics?.charts?.find((chart) => chart.type === ChartType.CGM_HEAT_MAP)?.series[0],
    );
    const loadingData = computed(() => !props.statistics);
    return { heatMapData, loadingData };
  },
});
