
import { computed, defineComponent, PropType, ref } from 'vue';
import { EventScoreDto, FinalAnalysisEventType } from '@/models/Event';
import { Serie, SerieName } from '@/models/Statistics';
import { useCgmDetailStore } from '@/store/cgmDetail.module';
import { ChartConfig, LegendItem } from '@/models/ChartConfig';
import moment from 'moment';
import { chartHelper } from '@/helpers/Chart.helper';
import utils from '@/helpers/utils.helper';
import dateFormat from '@/helpers/DateFormat.helper';
import ApplicationChart from '@/components/common/ApplicationChart.vue';
import i18n from '@/i18n';
import { useI18n } from 'vue-i18n';
export default defineComponent({
  components: {
    ApplicationChart,
  },
  emits: ['annotation-event-click'],
  props: {
    eventType: String as PropType<FinalAnalysisEventType>,
    events: {
      type: Array as PropType<EventScoreDto[]>,
      required: true,
    },
    series: {
      required: true,
      type: Array as PropType<Serie[]>,
    },
  },
  setup(props, context) {
    const { t } = useI18n();
    const storeCGM = useCgmDetailStore();
    const legendItems = ref<LegendItem[]>([
      {
        item: [0],
        visible: true,
        label: t(`common.median`),
        color: chartHelper.getSerieConfig(SerieName.Median)?.backgroundColor,
      },
      {
        item: [1, 2],
        visible: true,
        label: t(`common.variation`),
        color: chartHelper.getSerieConfig(SerieName.Open)?.backgroundColor,
      },
      {
        item: [3, 4],
        visible: true,
        label: t(`common.max-min`),
        color: chartHelper.getSerieConfig(SerieName.High)?.backgroundColor,
      },
    ]);

    const medicalTestDates = computed(() => storeCGM.getmedicalTestSelectedDates);
    const patientTimeZone = storeCGM.getMedicalTestSelectedUserTimezone;
    const chartStartEndDates = computed(() => {
      return {
        startDateChart: dateFormat.utcToTimezone(medicalTestDates.value.startDate, patientTimeZone),
        endDateChart: dateFormat.utcToTimezone(medicalTestDates.value.endDate, patientTimeZone),
      };
    });

    function getAxisFormatted(axis: string[]) {
      moment.locale(i18n.global.locale.value);
      return axis.map((date: any) => {
        const patientDate = dateFormat.utcToTimezone(date, patientTimeZone);
        return moment(patientDate).valueOf();
      });
    }

    const chartData = ref([
      {
        label: props.series?.find((serie) => serie.name == SerieName.Median)?.name,
        data: props.series?.find((serie) => serie.name == SerieName.Median)?.data,
        fill: chartHelper.getSerieConfig(SerieName.Median)?.fill,
        borderColor: chartHelper.getSerieConfig(SerieName.Median)?.backgroundColor,
        backgroundColor: chartHelper.getSerieConfig(SerieName.Median)?.backgroundColor,
        spanGaps: 1000 * 60 * 60,
      },
      {
        label: props.series?.find((serie) => serie.name == SerieName.Open)?.name,
        data: utils.takeMaxValuesBetweenTwoList(
          props.series?.find((serie) => serie.name == SerieName.Open)?.data,
          props.series?.find((serie) => serie.name == SerieName.Close)?.data,
        ),
        fill: chartHelper.getSerieConfig(SerieName.Open)?.fill,
        borderColor: chartHelper.getSerieConfig(SerieName.Open)?.backgroundColor,
        backgroundColor: chartHelper.getSerieConfig(SerieName.Open)?.backgroundColor,
        spanGaps: 1000 * 60 * 60,
      },
      {
        label: props.series?.find((serie) => serie.name == SerieName.Close)?.name,
        data: utils.takeMinValuesBetweenTwoList(
          props.series?.find((serie) => serie.name == SerieName.Open)?.data,
          props.series?.find((serie) => serie.name == SerieName.Close)?.data,
        ),
        fill: chartHelper.getSerieConfig(SerieName.Close)?.fill,
        borderColor: chartHelper.getSerieConfig(SerieName.Close)?.backgroundColor,
        backgroundColor: chartHelper.getSerieConfig(SerieName.Close)?.backgroundColor,
        spanGaps: 1000 * 60 * 60,
      },
      {
        label: props.series?.find((serie) => serie.name == SerieName.High)?.name,
        data: props.series?.find((serie) => serie.name == SerieName.High)?.data,
        fill: chartHelper.getSerieConfig(SerieName.High)?.fill,
        borderColor: chartHelper.getSerieConfig(SerieName.High)?.backgroundColor,
        backgroundColor: chartHelper.getSerieConfig(SerieName.High)?.backgroundColor,
        spanGaps: 1000 * 60 * 60,
      },
      {
        label: props.series?.find((serie) => serie.name == SerieName.Low)?.name,
        data: props.series?.find((serie) => serie.name == SerieName.Low)?.data,
        fill: chartHelper.getSerieConfig(SerieName.Low)?.fill,
        borderColor: chartHelper.getSerieConfig(SerieName.Low)?.backgroundColor,
        backgroundColor: chartHelper.getSerieConfig(SerieName.Low)?.backgroundColor,
        spanGaps: 1000 * 60 * 60,
      },
    ]);

    const maxValueSerie = computed(() => {
      const data = props.series?.find((serie) => serie.name == SerieName.High)?.data;
      return data ? Math.max(...data) : 180;
    });

    const annotation = computed(() => {
      return props.events && props.events.length > 0
        ? chartHelper.getEventsAnnotation({
            context,
            maxValue: maxValueSerie.value,
            events: props.events,
            timezone: patientTimeZone,
          })
        : null;
    });

    const options = computed(() => {
      return {
        animation: {
          duration: 0,
        },
        elements: {
          point: {
            radius: 0,
          },
          line: {
            borderWidth: 2,
          },
        },
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          title: {
            display: false,
          },
          legend: {
            display: false,
          },
          tooltip: {
            enabled: true,
            titleColor: '#233D4D',
            backgroundColor: '#ffffff',
            bodyColor: '#2E194B',
            bodyFont: {
              familiy: 'Rubik',
              weight: 'bold',
              size: 14,
            },
            titleFont: 'Rubik',
            cornerRadius: 4,
            padding: 8,
            usePointStyle: 'circle',
            callbacks: {
              label: function (text: any) {
                return text.parsed.y + ' mg/dL';
              },
              title: function (tooltipItems: any) {
                return dateFormat.formatDateWithHour(tooltipItems[0].parsed.x);
              },
            },
          },
          annotation: annotation.value,
        },
        interaction: {
          intersect: false,
        },
        scales: {
          x: {
            suggestedMin: chartStartEndDates.value.startDateChart,
            suggestedMax: chartStartEndDates.value.endDateChart,
            display: true,
            title: {
              display: true,
            },
            type: 'time',
            time: {
              unit: 'day',
              distribution: 'linear',
              format: 'MMM DD',
            },
            grid: {
              display: 'false',
              drawOnChartArea: false,
              drawBorder: true,
              drawTicks: false,
            },
            ticks: {
              color: '#545454',
              padding: 8,
              font: {
                size: 12,
                family: 'Rubik',
              },
            },
          },
          y: {
            suggestedMin: 40,
            suggestedMax: 200,
            grid: {
              display: 'true',
              drawOnChartArea: true,
              drawBorder: true,
              drawTicks: false,
            },
            ticks: {
              color: '#545454',
              padding: 8,
              font: {
                size: 12,
                family: 'Rubik',
              },
            },
          },
        },
      };
    });

    const chartConfig = computed<ChartConfig>(() => {
      const config = {} as ChartConfig;
      config.type = 'line';
      config.data = {
        labels: props.series ? getAxisFormatted(props.series[1].xaxis) : [],
        datasets: chartData.value,
      };
      config.options = options.value;
      return config;
    });

    const handleAnnotationEventClick = (eventId: string) => {
      context.emit('annotation-event-click', eventId);
    };

    function onClickLegendItem(element: LegendItem, index: number) {
      if (element) {
        legendItems.value[index].visible = !legendItems.value[index].visible;
      }
    }

    const chartConfigReady = computed(() =>
      Boolean(chartConfig.value && chartConfig.value.type && chartConfig.value.data && chartConfig.value.options),
    );

    return {
      chartConfig,
      chartStartEndDates,
      chartConfigReady,
      handleAnnotationEventClick,
      legendItems,
      onClickLegendItem,
    };
  },
});
