import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["aria-label"]
const _hoisted_2 = { class: "round-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_ScoreCircular = _resolveComponent("ScoreCircular")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_CardComponent = _resolveComponent("CardComponent")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_CardComponent, {
    class: "final-analysis-events__valuations-wrapper",
    border: true,
    "card-title": _ctx.$t('cgmDetail.valuations'),
    "edit-mode": _ctx.isValuationsEditable
  }, {
    headingActions: _withCtx(() => [
      _withDirectives((_openBlock(), _createBlock(_component_Button, {
        class: _normalizeClass(["p-button-rounded p-button-only-icon p-button-only-icon--dark", _ctx.isValuationsEditable ? 'p-disabled' : '']),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isValuationsEditable = !_ctx.isValuationsEditable))
      }, {
        default: _withCtx(() => [
          _createElementVNode("i", {
            class: "icon-edit",
            "aria-label": _ctx.$t('common.edit'),
            "aria-hidden": "true"
          }, null, 8, _hoisted_1)
        ]),
        _: 1
      }, 8, ["class"])), [
        [
          _directive_tooltip,
          _ctx.$t('common.edit'),
          void 0,
          { bottom: true }
        ]
      ])
    ]),
    cardContent: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["score-wrapper", _ctx.scoreCardClass])
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_ScoreCircular, {
            class: "valuation__circular-score",
            score: _ctx.score,
            color: _ctx.scoreHelper.scoreColor(_ctx.score)
          }, null, 8, ["score", "color"])
        ]),
        _cache[2] || (_cache[2] = _createElementVNode("p", null, "Puntuación media", -1))
      ], 2),
      _createVNode(_component_Textarea, {
        id: "observations",
        modelValue: _ctx.assessmentModel,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.assessmentModel) = $event)),
        rows: "5",
        "auto-resize": true,
        class: _normalizeClass(_ctx.isValuationsEditable ? '' : 'no-editable')
      }, null, 8, ["modelValue", "class"])
    ]),
    footerActions: _withCtx(() => [
      _createVNode(_component_Button, {
        class: "p-button-secondary p-button-medium",
        label: _ctx.$t('common.cancel'),
        onClick: _ctx.onCancel
      }, null, 8, ["label", "onClick"]),
      _createVNode(_component_Button, {
        class: "p-button p-button-info p-button-medium",
        loading: false,
        label: _ctx.$t('common.save'),
        onClick: _ctx.onAssessmentSave
      }, null, 8, ["label", "onClick"])
    ]),
    _: 1
  }, 8, ["card-title", "edit-mode"]))
}