
import { Recommendation } from '@/models/MedicalTest';
import { defineComponent, PropType, computed } from 'vue';

export default defineComponent({
  emits: ['update:recommendation', 'remove:recommendation'],
  props: {
    recommendation: {
      type: Object as PropType<Recommendation>,
      required: true,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
    recommendationsToRemove: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const recommendationModel = computed({
      get: () => props.recommendation.recommendation,
      set: (value) => emit('update:recommendation', { ...props.recommendation, recommendation: value }),
    });

    const isRemovable = computed(() => props.isEditable && !props.recommendation.defaultRecommendation);

    const isTemporallyRemoved = computed(() => props.recommendationsToRemove.includes(props.recommendation.id));

    const textareaClasses = computed(() => {
      return [isTemporallyRemoved.value ? 'disabled' : '', props.isEditable ? '' : 'no-editable'];
    });

    const onRemove = () => {
      emit('remove:recommendation', {
        id: props.recommendation.id,
        remove: !isTemporallyRemoved.value,
      });
    };
    return { recommendationModel, isRemovable, isTemporallyRemoved, textareaClasses, onRemove };
  },
});
