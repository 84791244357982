
import { defineComponent, nextTick, onMounted, onUnmounted, PropType } from 'vue';
import Chart from 'chart.js/auto';
import { ChartConfig, LegendItem } from '@/models/ChartConfig';
import annotationPlugin from 'chartjs-plugin-annotation';
import 'chartjs-adapter-moment';

export default defineComponent({
  emits: ['annotation-event-click', 'on-click-legend-item'],
  props: {
    chartId: {
      type: String,
      required: true,
      default: () => {
        return 'chart';
      },
    },
    chartConfig: {
      type: Object as PropType<ChartConfig>,
      required: true,
    },
    legendItems: {
      type: Array as PropType<LegendItem[]>,
      required: false,
    },
  },
  setup(props, { emit }) {
    Chart.register(annotationPlugin);
    let chart: Chart | null = null;

    onMounted(async () => {
      await handleDataChange(props.chartConfig);
    });

    onUnmounted(() => {
      if (chart) {
        chart.destroy();
      }
    });

    async function handleDataChange(chartConfig: ChartConfig | undefined) {
      if (chart) {
        chart?.destroy();
      }

      if (
        !chartConfig ||
        !chartConfig.data ||
        !chartConfig.data.datasets ||
        chartConfig.data.datasets.length == 0 ||
        !chartConfig.data.datasets[0].data
      ) {
        return;
      }

      const container = document.getElementById(props.chartId);
      if (!container) {
        await nextTick(() => {
          drawChart(chartConfig);
        });
      } else {
        drawChart(chartConfig);
      }
    }

    function drawChart(chartConfig: ChartConfig) {
      const container = document.getElementById(props.chartId);
      if (!container) {
        return;
      }

      const canvas = container as HTMLCanvasElement;
      if (!canvas) {
        return;
      }

      chart = new Chart(canvas, chartConfig as any);
    }

    async function changeFilter(filter: LegendItem, index: number) {
      filter.item.forEach((dataset) => {
        chart?.isDatasetVisible(dataset) ? chart.hide(dataset) : chart?.show(dataset);
      });

      emit('on-click-legend-item', filter, index);
    }

    return { changeFilter };
  },
});
