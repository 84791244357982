import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ApplicationChart = _resolveComponent("ApplicationChart")!

  return (_ctx.series && _ctx.chartStartEndDates && _ctx.chartConfig && _ctx.chartConfigReady)
    ? (_openBlock(), _createBlock(_component_ApplicationChart, {
        key: 0,
        chartId: 'day-glucose-line-chart-' + _ctx.eventType,
        chartConfig: _ctx.chartConfig,
        legendItems: _ctx.legendItems,
        onAnnotationEventClick: _ctx.handleAnnotationEventClick,
        onOnClickLegendItem: _ctx.onClickLegendItem
      }, null, 8, ["chartId", "chartConfig", "legendItems", "onAnnotationEventClick", "onOnClickLegendItem"]))
    : _createCommentVNode("", true)
}