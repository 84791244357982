const utils = {
  /**
   * Given a text and a list of words it returns true if the text has any of the words in the list
   * @param text any text
   * @param list list of words
   * @returns true if the text as any of the words in the list
   */
  textIncludesWordsFromList(text: string, list: string[]) {
    if (list.length == 0) {
      return true;
    }
    const wordsFromText = text.split(' ');
    return list.some((wordInlist: any) =>
      Boolean(wordsFromText.find((wordIntext) => wordIntext == wordInlist) != undefined),
    );
  },

  takeMaxValuesBetweenTwoList(first?: number[], second?: number[]) {
    if (first && second) {
      return first.map((item, i) => Math.max(item, second[i]));
    }
    return [];
  },

  takeMinValuesBetweenTwoList(first?: number[], second?: number[]) {
    if (first && second) {
      return first.map((item, i) => Math.min(item, second[i]));
    }
    return [];
  },
};
export default utils;
