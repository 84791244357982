
import {
  MedicalTest,
  BareRecommendation,
  MedicalTestRecommendation,
  MedicalTestState,
  Recommendation,
} from '@/models/MedicalTest';
import { measurementService } from '@/services/MeasurementService';
import moment from 'moment';
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useCgmDetailStore } from '@/store/cgmDetail.module';
import EmptyState from '@/components/EmptyState.vue';
import i18n from '@/i18n';
import dateFormat from '@/helpers/DateFormat.helper';
import CardComponent from '@/components/CardComponent.vue';
import CgmAnalysisEvents from '@/components/cgm/CgmAnalysisEvents.vue';
import { EventScoreDto, EventType, FinalAnalysisEventType } from '@/models/Event';
import { eventService } from '@/services/EventService';
import { medicalTestService } from '@/services/MedicalTestService';
import CgmAnalysisSummary from '@/components/cgm/CgmAnalysisSummary.vue';
import { ServiceError } from '@/services/util/ServiceError';
import { Chart, ChartType } from '@/models/Statistics';

export default defineComponent({
  components: { CardComponent, EmptyState, CgmAnalysisEvents, CgmAnalysisSummary },
  setup() {
    const storeCGM = useCgmDetailStore();
    const medicalTest = computed(() => storeCGM.getMedicalTestSelected as MedicalTest);

    const statistics = ref();
    const timezone = storeCGM.getMedicalTestSelectedUserTimezone;

    const medicalTestId = computed(() => medicalTest.value.id);
    const patientId = computed(() => medicalTest.value.patientId);
    const events = ref<EventScoreDto[] | undefined>([]);
    const recommendations = ref<MedicalTestRecommendation>();

    const scheduledFinishDate = computed(() => {
      const patientDate = dateFormat.utcToTimezone(medicalTest.value.startDate, timezone);
      return moment(patientDate).add(14, 'days').locale(i18n.global.locale.value).format('dddd DD');
    });

    const trainingEventType = FinalAnalysisEventType.TRAINING;
    const mealEventType = FinalAnalysisEventType.MEAL;
    const restEventType = FinalAnalysisEventType.REST;
    const otherEventType = FinalAnalysisEventType.OTHER;
    const feelingEventType = EventType.FEELING;

    const getEvents = () => {
      return eventService.findByMedicalTestAndPatientId(
        medicalTest.value.id,
        medicalTest.value.patientId,
        true,
        medicalTest.value.startDate,
        medicalTest.value.finishDate,
      );
    };

    const fetchRecommendations = async () => {
      const recommendationsResult = await medicalTestService.getRecommendations(
        medicalTest.value.patientId,
        medicalTest.value.id,
      );
      if (recommendationsResult && !(recommendationsResult instanceof ServiceError)) {
        recommendations.value = recommendationsResult;
      }
    };

    const trainingEvents = computed(() => {
      return events.value?.filter((event) => event.type === EventType.TRAINING) || [];
    });

    const mealEvents = computed(() => {
      return events.value?.filter((event) => event.type === EventType.MEAL) || [];
    });

    const restEvents = computed(() => {
      return events.value?.filter((event) => event.type === EventType.REST) || [];
    });

    const otherEvents = computed(() => {
      return events.value?.filter((event) => event.type === EventType.FEELING || event.type === EventType.CUSTOM) || [];
    });

    const series = computed(() => {
      return statistics.value?.charts?.find((chart: Chart) => chart.type == ChartType.CGM_CANDLESTICK)?.series;
    });

    const isMedicalTestStillInProgress = computed(
      () => !medicalTest.value || medicalTest.value?.state === MedicalTestState.IN_PROGRESS,
    );

    const isMedicalTestCanceled = computed(
      () => !medicalTest.value || medicalTest.value?.state === MedicalTestState.CANCELED,
    );

    const isMedicalTestStateStarted = computed(
      () => !medicalTest.value || medicalTest.value?.state === MedicalTestState.STARTED,
    );

    onMounted(async () => {
      if (medicalTest.value?.state === MedicalTestState.FINISHED) {
        const [statisticsData, eventsData] = await Promise.all([
          measurementService.findStatisticsCgm(
            medicalTest.value.id,
            medicalTest.value.patientId,
            medicalTest.value.startDate,
            medicalTest.value.finishDate,
          ),
          getEvents(),
        ]);

        if (!(statisticsData instanceof ServiceError)) {
          statistics.value = statisticsData;
        }

        if (!(eventsData instanceof ServiceError)) {
          events.value = eventsData;
        }

        await fetchRecommendations();
      }
    });

    const onAssessmentSave = (assessment: string, eventType: EventType) => {
      medicalTestService
        .updateEventAssessment(medicalTest.value.id, medicalTest.value.patientId, eventType, assessment)
        .catch((error: ServiceError) => {
          console.error(error);
        });
    };

    const onRecommendationsSave = async (payload: Recommendation[], eventType: EventType) => {
      const recommendationsToUpdate: BareRecommendation[] = payload.map((reco) => ({
        recommendation: reco.recommendation,
        defaultRecommendation: reco.defaultRecommendation,
      }));
      await medicalTestService.updateEventRecommendations(
        medicalTest.value.id,
        medicalTest.value.patientId,
        eventType,
        recommendationsToUpdate,
      );
      await fetchRecommendations();
    };

    return {
      storeCGM,
      medicalTest,
      isMedicalTestStillInProgress,
      scheduledFinishDate,
      trainingEventType,
      mealEventType,
      restEventType,
      otherEventType,
      feelingEventType,
      medicalTestId,
      patientId,
      trainingEvents,
      mealEvents,
      restEvents,
      otherEvents,
      recommendations,
      statistics,
      series,
      isMedicalTestCanceled,
      onAssessmentSave,
      onRecommendationsSave,
      isMedicalTestStateStarted,
    };
  },
});
