import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createVNode as _createVNode, createSlots as _createSlots } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "cgm-analysis__create-recommendation"
}
const _hoisted_2 = { class: "cgm-analysis__recommendations" }
const _hoisted_3 = { class: "cgm-analysis__create-recommendation" }
const _hoisted_4 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_Button = _resolveComponent("Button")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_CgmAnalysisRecommendationElement = _resolveComponent("CgmAnalysisRecommendationElement")!
  const _component_CardComponent = _resolveComponent("CardComponent")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_CardComponent, {
    "card-title": _ctx.$t('cgmDetail.recommendations'),
    "edit-mode": _ctx.isRecommendationsEditable
  }, _createSlots({
    headingActions: _withCtx(() => [
      _withDirectives((_openBlock(), _createBlock(_component_Button, {
        class: _normalizeClass(["p-button-rounded p-button-only-icon p-button-only-icon--dark", _ctx.isRecommendationsEditable ? 'p-disabled' : '']),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isRecommendationsEditable = !_ctx.isRecommendationsEditable))
      }, {
        default: _withCtx(() => [
          _createElementVNode("i", {
            class: "icon-edit",
            "aria-label": _ctx.$t('common.edit'),
            "aria-hidden": "true"
          }, null, 8, _hoisted_4)
        ]),
        _: 1
      }, 8, ["class"])), [
        [
          _directive_tooltip,
          _ctx.$t('common.edit'),
          void 0,
          { bottom: true }
        ]
      ])
    ]),
    footerActions: _withCtx(() => [
      _createVNode(_component_Button, {
        class: "p-button-secondary p-button-medium",
        label: _ctx.$t('common.cancel'),
        onClick: _ctx.onCancel
      }, null, 8, ["label", "onClick"]),
      _createVNode(_component_Button, {
        class: "p-button p-button-info p-button-medium",
        loading: false,
        label: _ctx.$t('common.save'),
        onClick: _ctx.onRecommendationsSave
      }, null, 8, ["label", "onClick"])
    ]),
    _: 2
  }, [
    (_ctx.isRecommendationValuesEmpty)
      ? {
          name: "cardContent",
          fn: _withCtx(() => [
            (_ctx.isRecommendationsEditable)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.newRecommendations, (_recommendation, index) => {
                    return (_openBlock(), _createBlock(_component_Textarea, {
                      id: `new-recommendation-${index}`,
                      key: index,
                      modelValue: _ctx.newRecommendations[index],
                      "onUpdate:modelValue": ($event: any) => ((_ctx.newRecommendations[index]) = $event),
                      class: "cgm-analysis__create-recommendation-textarea",
                      "auto-resize": true
                    }, null, 8, ["id", "modelValue", "onUpdate:modelValue"]))
                  }), 128)),
                  (_ctx.isRecommendationsEditable)
                    ? (_openBlock(), _createBlock(_component_Button, {
                        key: 0,
                        class: "cgm-analysis__create-recommendation-button",
                        disabled: !_ctx.canAddNewRecommendation,
                        onClick: _ctx.addNewRecommendation
                      }, {
                        default: _withCtx(() => [
                          _cache[1] || (_cache[1] = _createElementVNode("i", {
                            class: "icon-plus_circle_outline",
                            "aria-hidden": "true"
                          }, null, -1)),
                          _createTextVNode(" " + _toDisplayString(_ctx.$t('cgmFinalAnalysis.create-recommendation')), 1)
                        ]),
                        _: 1
                      }, 8, ["disabled", "onClick"]))
                    : _createCommentVNode("", true)
                ]))
              : (_openBlock(), _createBlock(_component_EmptyState, {
                  key: 1,
                  heading: _ctx.$t('cgmFinalAnalysis.recommendations-empty-title'),
                  text: _ctx.$t('cgmFinalAnalysis.recommendations-empty-message'),
                  secondary: false,
                  icon: "icon-bar_chart"
                }, null, 8, ["heading", "text"]))
          ]),
          key: "0"
        }
      : {
          name: "cardContent",
          fn: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.recommendationValues, (recommendation) => {
                return (_openBlock(), _createBlock(_component_CgmAnalysisRecommendationElement, {
                  key: recommendation.id,
                  recommendation: recommendation,
                  isEditable: _ctx.isRecommendationsEditable,
                  recommendationsToRemove: _ctx.recommendationsToRemove,
                  "onUpdate:recommendation": _ctx.onUpdateRecommendation,
                  "onRemove:recommendation": _ctx.onRemoveRecommendation
                }, null, 8, ["recommendation", "isEditable", "recommendationsToRemove", "onUpdate:recommendation", "onRemove:recommendation"]))
              }), 128))
            ]),
            _createElementVNode("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.newRecommendations, (_recommendation, index) => {
                return (_openBlock(), _createBlock(_component_Textarea, {
                  id: `new-recommendation-${index}`,
                  key: index,
                  modelValue: _ctx.newRecommendations[index],
                  "onUpdate:modelValue": ($event: any) => ((_ctx.newRecommendations[index]) = $event),
                  class: "cgm-analysis__create-recommendation-textarea",
                  "auto-resize": true
                }, null, 8, ["id", "modelValue", "onUpdate:modelValue"]))
              }), 128)),
              (_ctx.isRecommendationsEditable)
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 0,
                    class: "cgm-analysis__create-recommendation-button",
                    disabled: !_ctx.canAddNewRecommendation,
                    onClick: _ctx.addNewRecommendation
                  }, {
                    default: _withCtx(() => [
                      _cache[2] || (_cache[2] = _createElementVNode("i", {
                        class: "icon-plus_circle_outline",
                        "aria-hidden": "true"
                      }, null, -1)),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('cgmFinalAnalysis.create-recommendation')), 1)
                    ]),
                    _: 1
                  }, 8, ["disabled", "onClick"]))
                : _createCommentVNode("", true)
            ])
          ]),
          key: "1"
        }
  ]), 1032, ["card-title", "edit-mode"]))
}