import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cgm-analysis-textarea-wrapper" }
const _hoisted_2 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_Button = _resolveComponent("Button")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Textarea, {
      id: `recommendation-${_ctx.recommendation.id}`,
      modelValue: _ctx.recommendationModel,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.recommendationModel) = $event)),
      rows: "5",
      "auto-resize": true,
      class: _normalizeClass(["cgm-analysis-recommendation-element__textarea", _ctx.textareaClasses]),
      disabled: _ctx.isTemporallyRemoved
    }, null, 8, ["id", "modelValue", "class", "disabled"]),
    (_ctx.isRemovable)
      ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
          key: 0,
          class: "p-button-rounded p-button-only-icon p-button-only-icon--dark",
          onClick: _ctx.onRemove
        }, {
          default: _withCtx(() => [
            _createElementVNode("i", {
              class: "icon-trash_full",
              "aria-label": _ctx.$t('common.remove'),
              "aria-hidden": "true"
            }, null, 8, _hoisted_2)
          ]),
          _: 1
        }, 8, ["onClick"])), [
          [
            _directive_tooltip,
            _ctx.$t('common.remove'),
            void 0,
            { bottom: true }
          ]
        ])
      : _createCommentVNode("", true)
  ]))
}