
import { defineComponent, onMounted, onUnmounted, PropType } from 'vue';
import CardComponent from '../CardComponent.vue';
import { Serie } from '@/models/Statistics';
import { DateTime } from 'luxon';
import { useI18n } from 'vue-i18n';
import Chart from 'chart.js/auto';
import { MatrixController, MatrixElement, MatrixDataPoint } from 'chartjs-chart-matrix';
import { useCgmDetailStore } from '@/store/cgmDetail.module';
import { MedicalTest } from '@/models/MedicalTest';
import dateFormat from '@/helpers/DateFormat.helper';
import annotationPlugin from 'chartjs-plugin-annotation';
export default defineComponent({
  components: { CardComponent },
  props: {
    chartData: {
      required: true,
      type: Object as PropType<Serie | undefined>,
    },
  },
  setup(props) {
    Chart.register(MatrixController, MatrixElement, annotationPlugin);
    const { t } = useI18n();
    const storeCGM = useCgmDetailStore();
    const medicalTest = storeCGM.getMedicalTestSelected as MedicalTest;
    const patientTimeZone = storeCGM.getMedicalTestSelectedUserTimezone;
    const initialDatePatientTimeZone = dateFormat.utcToTimezone(medicalTest.startDate, patientTimeZone);
    let chartObject: Chart | null = null;
    const xLabels = generateXLabels();
    const yLabels = generateYLabels();

    onMounted(() => {
      drawChart();
    });

    onUnmounted(() => {
      if (chartObject) {
        chartObject.destroy();
      }
    });

    const chartOptions = {
      plugins: {
        legend: {
          position: 'bottom',
          labels: {
            pointStyle: 'circle',
            usePointStyle: true,
            boxWidth: 7,
          },
        },
        tooltip: {
          displayColors: false,
          callbacks: {
            title() {
              return '';
            },
            label(context: any) {
              const v: any = context.dataset.data[context.dataIndex];
              return `${v.v}`;
            },
          },
        },
      },
      layout: {
        padding: 5,
      },
      scales: {
        x: {
          type: 'category',
          labels: xLabels,
          position: 'top',
          ticks: {
            display: true,
            autoSkip: false,
          },
          grid: {
            display: false,
            borderColor: 'transparent',
          },
        },
        y: {
          type: 'category',
          labels: yLabels,
          reverse: false,
          offset: true,
          ticks: {
            display: true,
            autoSkip: false,
          },
          grid: {
            display: false,
            borderColor: 'transparent',
          },
        },
      },
    };

    function generateXLabels() {
      return [
        '00',
        '01',
        '02',
        '03',
        '04',
        '05',
        '06',
        '07',
        '08',
        '09',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19',
        '20',
        '21',
        '22',
        '23',
      ];
    }

    function generateYLabels() {
      let date = DateTime.fromISO(initialDatePatientTimeZone);
      const finalDate = date.plus({ days: 14 });
      const labels: string[] = [];

      while (date < finalDate) {
        const formattedDate = `${date.weekdayShort.substring(0, 1).toUpperCase()} ${date.day}`;
        labels.push(formattedDate);
        date = date.plus({ days: 1 });
      }

      return labels;
    }

    const generateData = (min: number, max: number) => {
      const data: { x: string; y: string; v: number }[] = [];
      props.chartData?.xaxis.forEach((date, index) => {
        const dateTime = DateTime.fromISO(dateFormat.utcToTimezone(date, patientTimeZone));
        const formattedTime = dateTime.hour < 10 ? '0' + dateTime.hour.toString() : dateTime.hour.toString();
        const formattedDate = `${dateTime.weekdayShort.substring(0, 1).toUpperCase()} ${dateTime.day}`;
        const value = props.chartData?.data[index] || 0;

        if (min <= value && value < max) {
          data.push({
            x: formattedTime,
            y: formattedDate,
            v: value,
          });
        }
      });

      return data as unknown[] as MatrixDataPoint[];
    };

    const generateEmptyData = () => {
      const data: { x: string; y: string; v: number }[] = [];
      let date = DateTime.fromISO(initialDatePatientTimeZone);
      const finalDate = date.plus({ days: 14 });

      while (date < finalDate) {
        let dateTime = date;
        for (let index = 0; index < 24; index++) {
          if (
            !props.chartData?.xaxis.includes(
              dateTime.toUTC().toISO({ suppressMilliseconds: true, includeOffset: false }),
            )
          ) {
            const formattedTime = dateTime.hour < 10 ? '0' + dateTime.hour.toString() : dateTime.hour.toString();
            const formattedDate = `${dateTime.weekdayShort.substring(0, 1).toUpperCase()} ${dateTime.day}`;
            data.push({
              x: formattedTime,
              y: formattedDate,
              v: 0,
            });
          }
          dateTime = dateTime.plus({ hours: 1 });
        }
        date = date.plus({ days: 1 });
      }

      return data as unknown[] as MatrixDataPoint[];
    };

    const generateDatasets = () => {
      return [
        {
          label: t('cgmHeatMap.no-data'),
          data: generateEmptyData(),
          backgroundColor: '#D2D9DF',
          borderWidth: 0,
          width: ({ chart }: { chart: any }) => (chart.chartArea || {}).width / 25 - 1,
          height: ({ chart }: { chart: any }) => (chart.chartArea || {}).height / 14 - 1,
        },
        {
          label: t('cgmHeatMap.hypoglycemia'),
          data: generateData(1, 55),
          backgroundColor: '#1368B9',
          borderWidth: 0,
          width: ({ chart }: { chart: any }) => (chart.chartArea || {}).width / 25 - 1,
          height: ({ chart }: { chart: any }) => (chart.chartArea || {}).height / 14 - 1,
        },
        {
          label: t('cgmHeatMap.low-glucose'),
          data: generateData(55, 70),
          backgroundColor: '#89C0F2',
          borderWidth: 0,
          width: ({ chart }: { chart: any }) => (chart.chartArea || {}).width / 25 - 1,
          height: ({ chart }: { chart: any }) => (chart.chartArea || {}).height / 14 - 1,
        },
        {
          label: t('cgmHeatMap.normal'),
          data: generateData(70, 110),
          backgroundColor: '#00B833',
          borderWidth: 0,
          width: ({ chart }: { chart: any }) => (chart.chartArea || {}).width / 25 - 1,
          height: ({ chart }: { chart: any }) => (chart.chartArea || {}).height / 14 - 1,
        },
        {
          label: t('cgmHeatMap.high-glucose'),
          data: generateData(110, 180),
          backgroundColor: '#F5A500',
          borderWidth: 0,
          width: ({ chart }: { chart: any }) => (chart.chartArea || {}).width / 25 - 1,
          height: ({ chart }: { chart: any }) => (chart.chartArea || {}).height / 14 - 1,
        },
        {
          label: t('cgmHeatMap.hyperglycemia'),
          data: generateData(180, 300),
          backgroundColor: '#C90000',
          borderWidth: 0,
          width: ({ chart }: { chart: any }) => (chart.chartArea || {}).width / 25 - 1,
          height: ({ chart }: { chart: any }) => (chart.chartArea || {}).height / 14 - 1,
        },
      ];
    };

    const drawChart = () => {
      const container = document.getElementById('heatmap');

      if (!container) {
        return;
      }
      const canvas = container as HTMLCanvasElement;
      if (!canvas) {
        return;
      }

      chartObject = new Chart(canvas, {
        type: 'matrix',
        data: {
          datasets: generateDatasets(),
        },
        options: chartOptions as any,
      });
    };

    return {};
  },
});
