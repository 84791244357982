import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_CardComponent = _resolveComponent("CardComponent")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_CardComponent, {
    cardTitle: _ctx.$t('cgmFinalAnalysis.recommendations'),
    border: true,
    "edit-mode": _ctx.editMode
  }, {
    headingActions: _withCtx(() => [
      _withDirectives((_openBlock(), _createBlock(_component_Button, {
        class: _normalizeClass(["p-button-rounded p-button-only-icon p-button-only-icon--dark", _ctx.editMode ? 'p-disabled' : '']),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.editMode = !_ctx.editMode))
      }, {
        default: _withCtx(() => [
          _createElementVNode("i", {
            class: "icon-edit",
            "aria-label": _ctx.$t('common.edit'),
            "aria-hidden": "true"
          }, null, 8, _hoisted_1)
        ]),
        _: 1
      }, 8, ["class"])), [
        [
          _directive_tooltip,
          _ctx.$t('common.edit'),
          void 0,
          { bottom: true }
        ]
      ])
    ]),
    cardContent: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentRecommendations, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          key: item.scoreType,
          class: "recomendation-wrapper"
        }, [
          _createVNode(_component_Textarea, {
            id: 'recommendation-' + item.scoreType,
            modelValue: item.recommendation,
            "onUpdate:modelValue": ($event: any) => ((item.recommendation) = $event),
            rows: "2",
            "auto-resize": true,
            class: _normalizeClass(_ctx.editMode ? '' : 'no-editable')
          }, null, 8, ["id", "modelValue", "onUpdate:modelValue", "class"])
        ]))
      }), 128))
    ]),
    footerActions: _withCtx(() => [
      _createVNode(_component_Button, {
        class: "p-button-secondary p-button-medium",
        label: _ctx.$t('common.cancel'),
        onClick: _ctx.onCancelEdition
      }, null, 8, ["label", "onClick"]),
      _createVNode(_component_Button, {
        class: "p-button p-button-info p-button-medium",
        loading: _ctx.submitting,
        label: _ctx.$t('common.save'),
        onClick: _ctx.onSubmit
      }, null, 8, ["loading", "label", "onClick"])
    ]),
    _: 1
  }, 8, ["cardTitle", "edit-mode"]))
}