
import { EventType, FinalAnalysisEventType } from '@/models/Event';
import { MedicalTestRecommendation, Recommendation } from '@/models/MedicalTest';
import CgmAnalysisRecommendationElement from '@/components/cgm/CgmAnalysisRecommendationElement.vue';
import { computed, defineComponent, PropType, Ref, ref } from 'vue';
import CardComponent from '@/components/CardComponent.vue';
import EmptyState from '../EmptyState.vue';

export default defineComponent({
  components: {
    CgmAnalysisRecommendationElement,
    CardComponent,
    EmptyState,
  },
  emits: ['onRecommendationsSave'],
  props: {
    eventType: String as PropType<FinalAnalysisEventType>,
    recommendations: {
      type: Object as PropType<MedicalTestRecommendation>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const isRecommendationsEditable = ref(false);
    const recommendationToCreate = ref('');
    const newRecommendations: Ref<string[]> = ref([]);
    const eventRecommendations = computed(() => {
      if (props.eventType === FinalAnalysisEventType.OTHER) {
        return props.recommendations.eventTypeRecommendationList.find((typedRecommendation) => {
          return typedRecommendation.type === EventType.FEELING;
        });
      } else {
        return props.recommendations.eventTypeRecommendationList.find((typedRecommendation) => {
          return typedRecommendation.type === (props.eventType as any as EventType);
        });
      }
    });

    const recommendationValues = computed(() => {
      const recommendationList = eventRecommendations.value;
      if (recommendationList) {
        return recommendationList.recommendationList.map((recommendation) => {
          return { ...recommendation, id: recommendation.recommendation.trim().replaceAll(' ', '-') };
        });
      } else {
        return [];
      }
    });

    const isRecommendationValuesEmpty = computed(() => {
      return recommendationValues.value.length === 0;
    });

    const recommendationsToUpdate = ref<Map<string, Recommendation | null>>(new Map());
    const recommendationsToRemove = ref<string[]>([]);

    const isRecommendationCreationEnabled = ref(false);

    const displayCreateRecommendationTextarea = computed(() => {
      return isRecommendationCreationEnabled.value && isRecommendationsEditable.value;
    });

    recommendationValues.value.forEach((r: Recommendation) => {
      recommendationsToUpdate.value.set(r.id, null);
    });

    const canAddNewRecommendation = computed(() => {
      const alreadyAnEmptyTextarea = newRecommendations.value.find((value) => {
        return value === '';
      });
      return alreadyAnEmptyTextarea !== '';
    });

    const addNewRecommendation = () => {
      if (canAddNewRecommendation.value) {
        newRecommendations.value.push('');
      }
    };

    const onCancel = () => {
      isRecommendationsEditable.value = false;
      isRecommendationCreationEnabled.value = false;
      newRecommendations.value = [];
      recommendationsToRemove.value = [];
    };

    const onRecommendationsSave = () => {
      const recommendationsToUpdateArray = Array.from(recommendationsToUpdate.value.values()).filter(
        (value) => value !== null,
      );

      const recommendationsToEmit = ref([...recommendationValues.value]);

      recommendationsToEmit.value = recommendationsToEmit.value.filter((r) => {
        return !recommendationsToRemove.value.includes(r.id);
      });

      recommendationsToEmit.value = recommendationsToEmit.value.map((r) => {
        const recommendation = recommendationsToUpdateArray.find((lookedUpRecommendation) => {
          return lookedUpRecommendation?.id === r.id;
        });
        if (recommendation) {
          return { ...r, recommendation: recommendation.recommendation };
        } else {
          return r;
        }
      });

      newRecommendations.value.forEach((newRecommendation) => {
        if (newRecommendation !== '') {
          recommendationsToEmit.value.push({
            id: newRecommendation.trim().replaceAll(' ', '-'),
            recommendation: newRecommendation,
            defaultRecommendation: false,
          });
        }
      });

      emit('onRecommendationsSave', recommendationsToEmit.value);
      onCancel();
    };

    const onUpdateRecommendation = (recommendation: Recommendation) => {
      recommendationsToUpdate.value.set(recommendation.id, recommendation);
    };

    const onRemoveRecommendation = (payload: { id: string; remove: boolean }) => {
      if (payload.remove) {
        recommendationsToRemove.value.push(payload.id);
      } else {
        recommendationsToRemove.value = recommendationsToRemove.value.filter((id) => id !== payload.id);
      }
    };
    return {
      eventRecommendations,
      recommendationValues,
      isRecommendationsEditable,
      recommendationsToRemove,
      recommendationToCreate,
      displayCreateRecommendationTextarea,
      isRecommendationCreationEnabled,
      newRecommendations,
      canAddNewRecommendation,
      isRecommendationValuesEmpty,
      onCancel,
      onRecommendationsSave,
      onUpdateRecommendation,
      onRemoveRecommendation,
      addNewRecommendation,
    };
  },
});
