import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "p-grid--summary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingComponent = _resolveComponent("LoadingComponent")!
  const _component_CgmAnalysisSummaryScores = _resolveComponent("CgmAnalysisSummaryScores")!
  const _component_CgmAnalysisSummaryRecommendations = _resolveComponent("CgmAnalysisSummaryRecommendations")!
  const _component_CgmAnalysisSummaryHeatMap = _resolveComponent("CgmAnalysisSummaryHeatMap")!

  return (_ctx.loadingData)
    ? (_openBlock(), _createBlock(_component_LoadingComponent, {
        key: 0,
        "spinner-color": "lavender-700"
      }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_CgmAnalysisSummaryScores, {
          scores: _ctx.statistics?.scores
        }, null, 8, ["scores"]),
        _createVNode(_component_CgmAnalysisSummaryRecommendations),
        _createVNode(_component_CgmAnalysisSummaryHeatMap, { chartData: _ctx.heatMapData }, null, 8, ["chartData"])
      ]))
}