import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CardComponent = _resolveComponent("CardComponent")!

  return (_openBlock(), _createBlock(_component_CardComponent, {
    cardTitle: _ctx.$t('cgmFinalAnalysis.heat-map'),
    border: true
  }, {
    cardContent: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("div", { class: "c-chart__container" }, [
        _createElementVNode("canvas", { id: "heatmap" })
      ], -1)
    ])),
    _: 1
  }, 8, ["cardTitle"]))
}