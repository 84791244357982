
import { computed, defineComponent, PropType } from 'vue';
import CardComponent from '@/components/CardComponent.vue';
import { EventScoreDto, FinalAnalysisEventType } from '@/models/Event';
import { MedicalTest, MedicalTestRecommendation } from '@/models/MedicalTest';
import EventsTable from './EventsTable.vue';
import { Serie } from '@/models/Statistics';
import { useRouter, useRoute } from 'vue-router';
import CgmFinalGlucoseOverviewChart from '@/components/charts/CgmFinalGlucoseOverviewChart.vue';
import CgmAnalysisValuation from './CgmAnalysisValuation.vue';
import CgmAnalysisRecommendations from './CgmAnalysisRecommendations.vue';
import EmptyState from '@/components/EmptyState.vue';

export default defineComponent({
  components: {
    CardComponent,
    EventsTable,
    CgmFinalGlucoseOverviewChart,
    CgmAnalysisValuation,
    CgmAnalysisRecommendations,
    EmptyState,
  },
  emits: ['onAssessmentSave', 'onRecommendationsSave'],
  props: {
    eventType: String as PropType<FinalAnalysisEventType>,
    medicalTest: {
      type: Object as PropType<MedicalTest>,
      required: true,
    },
    events: {
      type: Array as PropType<EventScoreDto[]>,
      required: true,
    },
    series: {
      required: true,
      type: Array as PropType<Serie[]>,
    },
    recommendations: {
      type: Object as PropType<MedicalTestRecommendation>,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();
    const medicalTestId = route.params.medicalTest as string;
    const patientId = route.params.patientId as string;
    const handleAnnotationEventClick = async (eventId: string) => {
      await router.push(`/patient/${patientId}/medical-test/cgm/${medicalTestId}/event/${eventId}`);
    };

    const isEventsEmpty = computed(() => {
      return !props.events || props.events.length === 0;
    });

    return { handleAnnotationEventClick, isEventsEmpty };
  },
});
