import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "final-analysis-events__grid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CgmFinalGlucoseOverviewChart = _resolveComponent("CgmFinalGlucoseOverviewChart")!
  const _component_CardComponent = _resolveComponent("CardComponent")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_EventsTable = _resolveComponent("EventsTable")!
  const _component_CgmAnalysisValuation = _resolveComponent("CgmAnalysisValuation")!
  const _component_CgmAnalysisRecommendations = _resolveComponent("CgmAnalysisRecommendations")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CardComponent, {
      class: "final-analysis-events__chart-wrapper",
      "card-title": _ctx.$t('cgmFinalAnalysis.glucose-summary')
    }, {
      cardContent: _withCtx(() => [
        (_ctx.series && _ctx.events)
          ? (_openBlock(), _createBlock(_component_CgmFinalGlucoseOverviewChart, {
              key: 0,
              events: _ctx.events,
              series: _ctx.series,
              eventType: _ctx.eventType,
              onAnnotationEventClick: _ctx.handleAnnotationEventClick
            }, null, 8, ["events", "series", "eventType", "onAnnotationEventClick"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["card-title"]),
    _createVNode(_component_CardComponent, {
      class: "final-analysis-events__table-wrapper",
      "edit-mode": true,
      border: true,
      "card-title": _ctx.$t('events.related-events')
    }, {
      cardContent: _withCtx(() => [
        (_ctx.isEventsEmpty)
          ? (_openBlock(), _createBlock(_component_EmptyState, {
              key: 0,
              heading: _ctx.$t('events.events-of-type-empty-title'),
              text: _ctx.$t('events.events-of-type-empty-message'),
              secondary: false,
              icon: "icon-bar_chart"
            }, null, 8, ["heading", "text"]))
          : (_openBlock(), _createBlock(_component_EventsTable, {
              key: 1,
              events: _ctx.events,
              "display-global-filters": false,
              fields: ['name', 'score', 'type', 'actions']
            }, null, 8, ["events"]))
      ]),
      _: 1
    }, 8, ["card-title"]),
    _createVNode(_component_CgmAnalysisValuation, {
      recommendations: _ctx.recommendations,
      eventType: _ctx.eventType,
      onOnAssessmentSave: _cache[0] || (_cache[0] = (assessment) => _ctx.$emit('onAssessmentSave', assessment))
    }, null, 8, ["recommendations", "eventType"]),
    _createVNode(_component_CgmAnalysisRecommendations, {
      recommendations: _ctx.recommendations,
      eventType: _ctx.eventType,
      onOnRecommendationsSave: _cache[1] || (_cache[1] = 
        (recommendations) => {
          _ctx.$emit('onRecommendationsSave', recommendations);
        }
      )
    }, null, 8, ["recommendations", "eventType"])
  ]))
}