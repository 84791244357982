import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_CgmAnalysisSummary = _resolveComponent("CgmAnalysisSummary")!
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_CgmAnalysisEvents = _resolveComponent("CgmAnalysisEvents")!
  const _component_TabView = _resolveComponent("TabView")!
  const _component_CardComponent = _resolveComponent("CardComponent")!

  return (_ctx.isMedicalTestCanceled)
    ? (_openBlock(), _createBlock(_component_EmptyState, {
        key: 0,
        heading: _ctx.$t('medicalTest.canceled-test-final-analysis-heading'),
        text: _ctx.$t('medicalTest.canceled-test-final-analysis-text'),
        icon: "icon-window_close"
      }, null, 8, ["heading", "text"]))
    : (_ctx.isMedicalTestStateStarted)
      ? (_openBlock(), _createBlock(_component_EmptyState, {
          key: 1,
          heading: _ctx.$t('cgmDetail.not-started-title'),
          icon: "icon-loading",
          text: _ctx.$t('cgmDetail.not-started-message'),
          textAtBottom: true
        }, null, 8, ["heading", "text"]))
      : (_ctx.isMedicalTestStillInProgress)
        ? (_openBlock(), _createBlock(_component_EmptyState, {
            key: 2,
            heading: _ctx.$t('final-analysis.empty-state-title'),
            text: _ctx.$t('final-analysis.empty-state-message', { day: _ctx.scheduledFinishDate }),
            secondary: false,
            icon: "icon-bar_chart"
          }, null, 8, ["heading", "text"]))
        : (_openBlock(), _createBlock(_component_CardComponent, {
            key: 3,
            cardTitle: _ctx.$t('cgmFinalAnalysis.title')
          }, {
            cardContent: _withCtx(() => [
              _createVNode(_component_TabView, null, {
                default: _withCtx(() => [
                  _createVNode(_component_TabPanel, {
                    header: _ctx.$t('cgmFinalAnalysis.SUMMARY')
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_CgmAnalysisSummary, { statistics: _ctx.statistics }, null, 8, ["statistics"])
                    ]),
                    _: 1
                  }, 8, ["header"]),
                  _createVNode(_component_TabPanel, {
                    header: _ctx.$t('cgmFinalAnalysis.TRAINING')
                  }, {
                    default: _withCtx(() => [
                      (_ctx.trainingEvents && _ctx.series && _ctx.recommendations)
                        ? (_openBlock(), _createBlock(_component_CgmAnalysisEvents, {
                            key: 0,
                            eventType: _ctx.trainingEventType,
                            medicalTest: _ctx.medicalTest,
                            events: _ctx.trainingEvents,
                            series: _ctx.series,
                            recommendations: _ctx.recommendations,
                            onOnAssessmentSave: _cache[0] || (_cache[0] = 
              (assessment) => {
                _ctx.onAssessmentSave(assessment, _ctx.trainingEventType as any);
              }
            ),
                            onOnRecommendationsSave: _cache[1] || (_cache[1] = (recommendationsToSavePayload) => {
  _ctx.onRecommendationsSave(recommendationsToSavePayload, _ctx.trainingEventType as any);
})
                          }, null, 8, ["eventType", "medicalTest", "events", "series", "recommendations"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["header"]),
                  _createVNode(_component_TabPanel, {
                    header: _ctx.$t('cgmFinalAnalysis.MEAL')
                  }, {
                    default: _withCtx(() => [
                      (_ctx.mealEvents && _ctx.series && _ctx.recommendations)
                        ? (_openBlock(), _createBlock(_component_CgmAnalysisEvents, {
                            key: 0,
                            eventType: _ctx.mealEventType,
                            medicalTest: _ctx.medicalTest,
                            events: _ctx.mealEvents,
                            series: _ctx.series,
                            recommendations: _ctx.recommendations,
                            onOnAssessmentSave: _cache[2] || (_cache[2] = 
              (assessment) => {
                _ctx.onAssessmentSave(assessment, _ctx.mealEventType as any);
              }
            ),
                            onOnRecommendationsSave: _cache[3] || (_cache[3] = (recommendationsToSavePayload) => {
  _ctx.onRecommendationsSave(recommendationsToSavePayload, _ctx.mealEventType as any);
})
                          }, null, 8, ["eventType", "medicalTest", "events", "series", "recommendations"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["header"]),
                  _createVNode(_component_TabPanel, {
                    header: _ctx.$t('cgmFinalAnalysis.REST')
                  }, {
                    default: _withCtx(() => [
                      (_ctx.restEvents && _ctx.series && _ctx.recommendations)
                        ? (_openBlock(), _createBlock(_component_CgmAnalysisEvents, {
                            key: 0,
                            eventType: _ctx.restEventType,
                            medicalTest: _ctx.medicalTest,
                            events: _ctx.restEvents,
                            series: _ctx.series,
                            recommendations: _ctx.recommendations,
                            onOnAssessmentSave: _cache[4] || (_cache[4] = 
              (assessment) => {
                _ctx.onAssessmentSave(assessment, _ctx.restEventType as any);
              }
            ),
                            onOnRecommendationsSave: _cache[5] || (_cache[5] = (recommendationsToSavePayload) => {
  _ctx.onRecommendationsSave(recommendationsToSavePayload, _ctx.restEventType as any);
})
                          }, null, 8, ["eventType", "medicalTest", "events", "series", "recommendations"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["header"]),
                  _createVNode(_component_TabPanel, {
                    header: _ctx.$t('cgmFinalAnalysis.OTHER')
                  }, {
                    default: _withCtx(() => [
                      (_ctx.otherEvents && _ctx.series && _ctx.recommendations)
                        ? (_openBlock(), _createBlock(_component_CgmAnalysisEvents, {
                            key: 0,
                            eventType: _ctx.otherEventType,
                            medicalTest: _ctx.medicalTest,
                            events: _ctx.otherEvents,
                            series: _ctx.series,
                            recommendations: _ctx.recommendations,
                            onOnAssessmentSave: _cache[6] || (_cache[6] = 
              (assessment) => {
                _ctx.onAssessmentSave(assessment, _ctx.feelingEventType);
              }
            ),
                            onOnRecommendationsSave: _cache[7] || (_cache[7] = (recommendationsToSavePayload) => {
  _ctx.onRecommendationsSave(recommendationsToSavePayload, _ctx.feelingEventType);
})
                          }, null, 8, ["eventType", "medicalTest", "events", "series", "recommendations"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["header"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["cardTitle"]))
}