
import { OverviewRecommendation } from '@/models/MedicalTest';
import { defineComponent, onMounted, ref, Ref } from 'vue';
import CardComponent from '../CardComponent.vue';
import scoreHelper from '@/helpers/Score.helper';
import { ScoreType } from '@/models/Score';
import { medicalTestService } from '@/services/MedicalTestService';
import { useRoute } from 'vue-router';
import { useToast } from 'primevue/usetoast';
import { ServiceError } from '@/services/util/ServiceError';
import { useI18n } from 'vue-i18n';
import inputHelper from '@/helpers/Input.helper';

export default defineComponent({
  components: { CardComponent },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const toast = useToast();
    const patientId = Array.isArray(route.params.patientId) ? route.params.patientId[0] : route.params.patientId;
    const medicalTestId = Array.isArray(route.params.medicalTest)
      ? route.params.medicalTest[0]
      : route.params.medicalTest;
    const recommendations: Ref<OverviewRecommendation[]> = ref([]);
    const currentRecommendations = ref();
    const editMode = ref(false);
    const submitting = ref(false);

    const valueAndMeasure = (item: OverviewRecommendation) =>
      item.value + scoreHelper.measurementUnit({ ...item, type: item.scoreType as ScoreType });

    onMounted(async () => {
      await loadRecommendations();
    });

    async function loadRecommendations() {
      const result = await medicalTestService.getRecommendations(patientId, medicalTestId);
      if (!(result instanceof ServiceError)) {
        if (!result || !result.overviewRecommendations) {
          toast.add({
            severity: 'error',
            summary: `${t('cgmFinalAnalysis.recommendations-load-error')} ${t('messages.notifications.tryLater')}`,
            life: 3000,
          });
        } else {
          recommendations.value.push(...result?.overviewRecommendations);
          currentRecommendations.value = inputHelper.createCopy<OverviewRecommendation[]>(
            recommendations.value?.filter((r) => r.recommendation.length),
          );
        }
      }
    }

    const onSubmit = async () => {
      submitting.value = true;
      const result = await medicalTestService.addOverviewRecommendations(
        patientId,
        medicalTestId,
        currentRecommendations.value,
      );
      submitting.value = false;
      if (result instanceof ServiceError) {
        toast.add({
          severity: 'error',
          summary: `${t('messages.notifications.errorEditPatient')} ${t('messages.notifications.tryLater')}`,
          life: 3000,
        });
      } else {
        editMode.value = false;
        toast.add({ severity: 'success', summary: `${t('messages.notifications.successEditPatient')}`, life: 3000 });
      }
    };

    const onCancelEdition = async () => {
      editMode.value = false;
      currentRecommendations.value = inputHelper.createCopy<OverviewRecommendation[]>(
        recommendations.value?.filter((r) => r.recommendation.length),
      );
    };

    return {
      currentRecommendations,
      editMode,
      submitting,
      onSubmit,
      onCancelEdition,
      valueAndMeasure,
    };
  },
});
