
import { EventType, FinalAnalysisEventType } from '@/models/Event';
import { MedicalTestRecommendation } from '@/models/MedicalTest';
import { computed, defineComponent, PropType, ref } from 'vue';
import ScoreCircular from '../charts/ScoreCircular.vue';
import scoreHelper from '@/helpers/Score.helper';
import CardComponent from '../CardComponent.vue';

export default defineComponent({
  components: {
    ScoreCircular,
    CardComponent,
  },
  emits: ['onAssessmentSave'],
  props: {
    eventType: String as PropType<FinalAnalysisEventType>,
    recommendations: {
      type: Object as PropType<MedicalTestRecommendation>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const assessment = computed(() => {
      if (props.eventType === FinalAnalysisEventType.OTHER) {
        const recommendationList = props.recommendations.eventTypeRecommendationList.find((typedRecommendation) => {
          return typedRecommendation.type === EventType.FEELING;
        });
        return recommendationList?.assessment;
      } else {
        const recommendationList = props.recommendations.eventTypeRecommendationList.find((typedRecommendation) => {
          return typedRecommendation.type === (props.eventType as any as EventType);
        });
        return recommendationList?.assessment;
      }
    });

    const score = computed(() => {
      if (props.eventType === FinalAnalysisEventType.OTHER) {
        const recommendationList = props.recommendations.eventTypeRecommendationList.find((typedRecommendation) => {
          return typedRecommendation.type === EventType.FEELING;
        });
        return recommendationList?.score;
      } else {
        const recommendationList = props.recommendations.eventTypeRecommendationList.find((typedRecommendation) => {
          return typedRecommendation.type === (props.eventType as any as EventType);
        });
        return recommendationList?.score;
      }
    });

    const assessmentModel = ref(assessment.value);
    const assessmentOriginalValue = assessment.value;

    const scoreCardClass = computed(() => [scoreHelper.scoreColor(score.value)]);
    const isValuationsEditable = ref(false);

    const onCancel = () => {
      assessmentModel.value = assessmentOriginalValue;
      isValuationsEditable.value = false;
    };

    const onAssessmentSave = () => {
      emit('onAssessmentSave', assessmentModel.value);
      isValuationsEditable.value = false;
    };

    return {
      assessment,
      score,
      scoreHelper,
      scoreCardClass,
      isValuationsEditable,
      assessmentModel,
      assessmentOriginalValue,
      onCancel,
      onAssessmentSave,
    };
  },
});
